import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const ArrowLeft: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 24,
  height = 24,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <Box
        component="path"
        d="M20 12C20 11.4477 19.5523 11 19 11L8.58334 10.5994L11.2071 7.20711C11.5976 6.81658 11.5976 6.18342 11.2071 5.79289C10.8166 5.40237 10.1834 5.40237 9.79289 5.79289L4.29289 11.2929C4.09763 11.4882 4 11.7441 4 12C4 12.2559 4.09763 12.5118 4.29289 12.7071L9.79289 18.2071C10.1834 18.5976 10.8166 18.5976 11.2071 18.2071C11.5976 17.8166 11.5976 17.1834 11.2071 16.7929L8.58334 13.4006L19 13C19.5523 13 20 12.5523 20 12Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
