import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const BoldShield: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 40,
  height = 48,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 40 48"
      fill="none"
    >
      <Box
        component="path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.092 3.95158L20 0L1.90789 3.95163C0.79461 4.19587 0 5.16574 0 6.28932V27.8414C0 32.2473 2.24017 36.3628 5.97365 38.8086L20 48L34.0264 38.8086C37.7577 36.365 40 32.2473 40 27.8393V6.28932C40 5.16574 39.2052 4.19582 38.092 3.95158ZM4.87377 27.8393V8.21028L20 4.90764L35.1264 8.21028V27.8414C35.1264 30.647 33.7002 33.2658 31.3239 34.8228L20 42.2443L8.67625 34.8228C6.29998 33.2658 4.87377 30.6449 4.87377 27.8393Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
      <Box
        component="path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6542 18.4573L28.2061 15.0707L17.3389 25.7495L11.1304 19.6473L7.68457 23.0342L17.3411 32.5254L31.6542 18.4573Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
