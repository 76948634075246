import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Linge: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 48,
  height = 49,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 49"
      fill="none"
    >
      <g clipPath="url(#clip0_172_822)">
        <Box
          component="path"
          d="M9.96606 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M13.7085 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M17.4509 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M21.1931 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M24.9355 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M28.678 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M32.4204 44.5568V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M36.1626 48.3771V27.3652C36.1626 26.3105 37.0006 25.4551 38.0338 25.4551C39.067 25.4551 39.905 26.3105 39.905 27.3652V46.4669"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M38.0338 25.4551H9.03041C7.48058 25.4551 6.22363 26.7382 6.22363 28.3203V48.3771"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M6.22367 27.3652H3.41689C1.86706 27.3652 0.610107 26.0821 0.610107 24.5V23.5449C0.610107 22.378 1.26909 21.3108 2.29824 20.8084L23.9999 10.1737L45.7016 20.8084C46.7308 21.3108 47.3898 22.378 47.3898 23.5449V24.5C47.3898 26.0821 46.1328 27.3652 44.583 27.3652H36.1626"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M20.2576 4.4432C20.2576 2.33371 21.9335 0.622864 23.9999 0.622864C26.0664 0.622864 27.7423 2.33371 27.7423 4.4432C27.7423 5.89244 26.9491 7.15481 25.7816 7.80261C24.8623 8.31337 23.9999 9.10651 23.9999 10.1737"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_822">
          <rect width="48" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
