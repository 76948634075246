import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Photo: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 52,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 52 57"
      fill="none"
    >
      <g clipPath="url(#clip0_172_867)">
        <Box
          component="path"
          d="M9.17331 56.3804H44.9649C48.4928 56.3804 51.3755 53.4974 51.3755 49.9783V29.702C51.3755 26.1787 48.4887 23.2998 44.9649 23.2998H37.2185V19.8302C37.2185 17.7774 35.5352 16.0963 33.4797 16.0963H17.9868C15.9313 16.0963 14.248 17.7774 14.248 19.8302V23.2998H7.03507C3.5072 23.2998 0.624512 26.1828 0.624512 29.702V49.9783C0.624512 53.5015 3.51133 56.3804 7.03507 56.3804H9.17744H9.17331Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M26.8002 51.8452C33.2918 51.8452 38.5543 46.5896 38.5543 40.1065C38.5543 33.6234 33.2918 28.3678 26.8002 28.3678C20.3086 28.3678 15.0461 33.6234 15.0461 40.1065C15.0461 46.5896 20.3086 51.8452 26.8002 51.8452Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M42.0283 28.3678H45.7671"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M25.9978 8.62435V0.619568"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M32.5161 11.2926L38.1822 5.63391"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M13.8137 5.63391L19.484 11.2926"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_867">
          <rect width="52" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
