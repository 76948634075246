import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Broom: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 49,
  height = 45,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 49 45"
      fill="none"
    >
      <g clipPath="url(#clip0_172_859)">
        <Box
          component="path"
          d="M38.8263 30.1103L18.7695 10.1333L22.1954 6.72103C23.9976 4.926 26.9168 4.926 28.719 6.72103L42.2522 20.2004C44.0544 21.9954 44.0544 24.903 42.2522 26.6981L38.8263 30.1103Z"
          // stroke={mColor}
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M48.2194 4.46692C47.4886 2.92832 46.0601 1.50552 44.5154 0.777581C43.9049 0.488058 43.1782 0.608003 42.655 1.03401C39.6735 3.44946 36.0982 6.63006 32.2944 10.2822L38.681 16.6434C42.3477 12.8548 45.541 9.29367 47.9661 6.324C48.3938 5.80286 48.5101 5.07905 48.2236 4.47106L48.2194 4.46692Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M3.60872 30.0565C2.95262 30.0979 2.54567 30.7845 2.80313 31.3883C6.12101 39.0979 12.8772 44.3796 20.6757 44.3796C29.587 44.3796 37.0242 38.2624 38.8223 30.1103L18.7697 10.1333C15.0988 14.2114 9.42228 16.7923 3.4883 16.7923C3.18517 16.7923 2.88618 16.784 2.59135 16.7675C1.51584 16.7096 0.623047 17.5947 0.623047 18.6659V20.5974C0.623047 21.2881 0.647962 21.9706 0.697793 22.6448C0.830674 24.4398 2.18856 25.937 3.98245 26.1604C4.75898 26.2555 5.55211 26.3093 6.35355 26.3093C10.9172 26.3093 15.1113 24.7748 18.4831 22.2187C15.0697 26.6857 9.6133 29.676 3.61288 30.0607L3.60872 30.0565Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M12.5405 42.3199C16.0162 41.9062 19.2178 40.6199 21.917 38.6719"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M5.97949 36.7486C6.10407 36.7486 6.22864 36.7693 6.35322 36.7693C11.8138 36.7693 16.7595 34.5896 20.3764 31.0616"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_859">
          <rect width="49" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
