import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Guaranteed: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  sColor,
  sColorOption,
  width = 63,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 63 57"
      fill="none"
    >
      <Box
        component="path"
        d="M31.4494 0.863647L0.632324 30.9369L5.56339 35.7489L31.3612 10.5738C32.9951 12.1683 57.159 35.7489 57.159 35.7489L62.0901 30.9369L49.9388 18.9068V3.26967H42.5422V11.6887L31.4494 0.863647ZM9.24219 32.7812V56.3824H53.7646V32.7812"
        sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Box
        component="path"
        d="M38.6697 42.1257L41.125 42.6317C40.18 46.1364 37.2902 48.3636 33.2402 48.3636C29.4349 48.3636 25.79 46.265 24.6298 41.9681H21.875V40.0851H24.2797C24.2544 39.6869 24.2248 39.2888 24.2248 38.8657C24.2248 38.3888 24.2501 37.9367 24.305 37.4846H21.875V35.6016H24.6551C25.8954 31.4084 29.4898 29.3636 33.2402 29.3636C37.345 29.3636 40.3699 31.6448 41.125 35.2283L38.695 35.7592C38.1297 33.0799 36.13 31.5121 33.2402 31.5121C30.3504 31.5121 28.2453 32.9181 27.275 35.5974H35.9149L35.2652 37.4804H26.8404C26.7856 37.9035 26.7603 38.3846 26.7603 38.8616C26.7603 39.2846 26.7856 39.6828 26.8151 40.081H34.3455L33.7254 41.964H27.2201C28.1651 44.7511 30.4052 46.1862 33.2402 46.1862C36.0752 46.1862 38.02 44.7013 38.6655 42.1257H38.6697Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
