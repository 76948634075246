import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const MaxRevenu: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 66,
  height = 56,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="56"
      viewBox="0 0 86 76"
      fill="none"
    >
      <Box
        component="path"
        d="M1.28174 54.7381L37.0419 19.1175L46.2075 28.2473L61.7947 12.721L54.4646 5.41957L84.718 1.7627L81.053 30.0758L72.8021 22.7682L58.278 37.2356"
        stroke="#001C64"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Box
        component="path"
        d="M11.8755 65.3362L36.4586 40.1729L41.012 44.6676"
        stroke="#001C64"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Box
        component="path"
        d="M56.9053 74.6053C67.8769 74.6053 76.7711 66.304 76.7711 56.0639C76.7711 45.8237 67.8769 37.5225 56.9053 37.5225C45.9338 37.5225 37.0396 45.8237 37.0396 56.0639C37.0396 66.304 45.9338 74.6053 56.9053 74.6053Z"
        stroke="#001C64"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Box
        component="path"
        d="M63.8121 59.2478L66.1766 59.7401C65.2654 63.1566 62.4839 65.3334 58.5826 65.3334C54.9136 65.3334 51.4055 63.2871 50.2857 59.0936H47.6353V57.2549H49.9522C49.9284 56.8694 49.8986 56.4779 49.8986 56.0627C49.8986 55.5941 49.9224 55.1552 49.976 54.7163H47.6353V52.8776H50.3155C51.5127 48.7849 54.9732 46.792 58.5826 46.792C62.5375 46.792 65.45 49.0162 66.1766 52.5157L63.8359 53.0318C63.2879 50.416 61.3641 48.8858 58.5826 48.8858C55.8011 48.8858 53.7701 50.2559 52.8349 52.8716H61.1556L60.5302 54.7103H52.418C52.3644 55.1255 52.3406 55.5882 52.3406 56.0568C52.3406 56.472 52.3644 56.8575 52.3942 57.249H59.6487L59.0531 59.0877H52.7873C53.6986 61.8042 55.8547 63.204 58.5886 63.204C61.3224 63.204 63.1926 61.7568 63.818 59.2419L63.8121 59.2478Z"
        fill="#0466C9"
      />
    </svg>
  </Box>
);
