import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Key: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 44,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 44 57"
      fill="none"
    >
      <g clipPath="url(#clip0_172_798)">
        <Box
          component="path"
          d="M36.6959 12.9407C39.181 13.8742 41.3003 15.737 42.4692 18.3102C44.7987 23.4485 42.4482 29.4707 37.2173 31.7631C35.9306 32.3248 34.5893 32.6098 33.2605 32.6428L33.2395 32.6924C33.2395 32.6924 32.3607 34.9394 31.8771 36.1826C31.6921 36.6576 31.5659 36.9839 31.5659 36.9839L29.577 37.8555L30.5441 39.6026L28.5889 40.5444L29.6485 42.6839L27.5503 43.5142L28.6561 45.3728L26.1921 46.3642L27.2265 48.2559L25.3595 48.8135L26.0996 50.8333L24.9054 54.0468L19.9604 56.3846L17.5132 51.3826L26.4191 28.6983"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M18.1737 15.8196H2.61557C1.5223 15.8196 0.630859 14.9439 0.630859 13.87V2.56913C0.630859 1.49522 1.5223 0.619568 2.61557 0.619568H29.1821C30.2754 0.619568 31.1668 1.49522 31.1668 2.56913V8.85979"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M30.4562 20.3672C32.5649 20.3672 34.2743 18.6881 34.2743 16.6168C34.2743 14.5455 32.5649 12.8663 30.4562 12.8663C28.3476 12.8663 26.6382 14.5455 26.6382 16.6168C26.6382 18.6881 28.3476 20.3672 30.4562 20.3672Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M31.171 2.87067C34.8377 2.87067 37.8147 5.79088 37.8147 9.39675C37.8147 13.0026 34.8419 15.9228 31.171 15.9228C29.7119 15.9228 28.3621 15.4602 27.2646 14.6754"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M21.4743 30.7387L24.1655 28.0952L24.2033 28.0581C25.4269 28.562 26.7725 28.8387 28.1812 28.8387C33.9082 28.8387 38.5504 24.2787 38.5504 18.6531C38.5504 13.0274 33.9082 8.46741 28.1812 8.46741C22.4541 8.46741 17.8119 13.0274 17.8119 18.6531C17.8119 20.0781 18.1104 21.4328 18.6445 22.6637C18.5814 22.7257 18.5183 22.7876 18.5183 22.7876L2.05615 38.8798L2.21594 44.427L7.70332 44.27L10.1253 41.8124L10.2851 39.6728L12.2236 39.9124L12.0638 37.7728L14.7255 37.8513L14.4816 35.7118L16.7396 35.7902L16.6597 33.4111L18.8379 33.3326L18.6781 31.35H20.8562C20.8562 31.35 21.1085 31.1022 21.4743 30.7428V30.7387Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_798">
          <rect width="44" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
