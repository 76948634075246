import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const ArrowRight: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 24,
  height = 24,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <Box
        component="path"
        d="M4 12C4 11.4477 4.44771 11 5 11L15.4167 10.5994L12.7929 7.20711C12.4024 6.81658 12.4024 6.18342 12.7929 5.79289C13.1834 5.40237 13.8166 5.40237 14.2071 5.79289L19.7071 11.2929C19.9024 11.4882 20 11.7441 20 12C20 12.2559 19.9024 12.5118 19.7071 12.7071L14.2071 18.2071C13.8166 18.5976 13.1834 18.5976 12.7929 18.2071C12.4024 17.8166 12.4024 17.1834 12.7929 16.7929L15.4167 13.4006L5 13C4.44772 13 4 12.5523 4 12Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
