import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const JanitorialExpert: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 62,
  height = 56,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <Box {...props} height={height} width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="62"
        height="56"
        viewBox="0 0 82 76"
        fill="none"
      >
        <Box
          component="path"
          d="M46.5662 45.3286C46.5662 46.82 45.9758 48.165 45.0025 49.1629C43.9965 50.1987 42.575 50.8441 41.0058 50.8441C39.4367 50.8441 38.0151 50.1987 37.0036 49.1629C36.0414 48.1704 35.4399 46.82 35.4399 45.3286C35.4399 44.3524 35.6969 43.4304 36.1452 42.6332C37.0911 40.9465 38.9173 39.8076 41.0058 39.8076C43.0944 39.8076 44.9096 40.9465 45.8609 42.6277C46.3147 43.4304 46.5662 44.3469 46.5662 45.3286Z"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M31.084 63.5889H50.7669"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M51.1444 55.7627C48.7934 52.769 45.1247 50.8438 41.0023 50.8438C36.8798 50.8438 33.0526 52.8504 30.707 55.9579"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M30.7142 56.7143C30.7142 58.2057 30.1237 59.5507 29.1505 60.5486C28.1445 61.5845 26.7229 62.2298 25.1538 62.2298C23.5846 62.2298 22.1631 61.5845 21.1516 60.5486C20.1893 59.5561 19.5879 58.2057 19.5879 56.7143C19.5879 55.7381 19.8449 54.8161 20.2932 54.0189C21.2391 52.3323 23.0652 51.1934 25.1538 51.1934C27.2423 51.1934 29.0575 52.3323 30.0089 54.0135C30.4627 54.8161 30.7142 55.7327 30.7142 56.7143Z"
          stroke="#0466C9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M38.0008 74.9733C38.0008 67.9339 32.249 62.2285 25.1523 62.2285C18.0555 62.2285 12.3037 67.9339 12.3037 74.9733H38.0008Z"
          stroke="#0466C9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M62.4241 56.7143C62.4241 58.2057 61.8337 59.5507 60.8605 60.5486C59.8544 61.5845 58.4329 62.2298 56.8637 62.2298C55.2946 62.2298 53.873 61.5845 52.8615 60.5486C51.8993 59.5561 51.2979 58.2057 51.2979 56.7143C51.2979 55.7381 51.5548 54.8161 52.0032 54.0189C52.949 52.3323 54.7752 51.1934 56.8637 51.1934C58.9523 51.1934 60.7675 52.3323 61.7188 54.0135C62.1726 54.8161 62.4241 55.7327 62.4241 56.7143Z"
          stroke="#0466C9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M69.7108 74.9743C69.7108 67.9348 63.959 62.2295 56.8622 62.2295C49.7654 62.2295 44.0137 67.9348 44.0137 74.9743H69.7108Z"
          stroke="#0466C9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M70.1616 57.867V43.9941"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M12.208 43.9941V57.867"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Box
          component="path"
          d="M41.1148 1.7627L1 41.5536L7.41881 47.9206L41 14.6106C43.1268 16.7202 74.5812 47.9206 74.5812 47.9206L81 41.5536L65.1826 25.6362V4.94619H55.5544V16.0857L41.1148 1.7627Z"
          stroke="#001C64"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  </Box>
);
