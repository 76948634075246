import { Box, type BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const Star: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  width = 48,
  height = 47,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  ...props
}) => (
  <Box {...props} width={width} height={height}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 47"
      fill="none"
    >
      <Box
        component="path"
        d="M23.4715 38.7096C23.795 38.5081 24.205 38.5081 24.5285 38.7096L37.4181 46.7349C38.1719 47.2042 39.1179 46.5315 38.9221 45.6654L35.4798 30.444C35.3997 30.0897 35.518 29.7199 35.7888 29.4778L47.2764 19.2119C47.9295 18.6282 47.5699 17.5464 46.6972 17.47L31.6314 16.1515C31.2589 16.1189 30.9358 15.8812 30.7937 15.5353L24.925 1.25133C24.5854 0.424706 23.4147 0.424704 23.075 1.25133L17.2063 15.5353C17.0642 15.8812 16.7411 16.1189 16.3686 16.1515L1.30275 17.47C0.430148 17.5464 0.0704525 18.6282 0.72359 19.2119L12.2112 29.4778C12.482 29.7199 12.6003 30.0898 12.5202 30.444L9.07795 45.6654C8.88209 46.5315 9.82807 47.2042 10.5819 46.7349L23.4715 38.7096Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
