import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Shield: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  sColor,
  sColorOption,
  width = 46,
  height = 56,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 46 56"
      fill="none"
    >
      <Box
        component="path"
        d="M22.9952 1L22.8413 1.05122L1.64188 8.13151C-3.39263 44.4234 22.8413 54.1357 22.8413 54.1357L22.9952 54.1948C23.0741 54.1672 23.149 54.1357 23.149 54.1357C23.149 54.1357 49.3829 44.4234 44.3484 8.13151L23.149 1.05122C23.149 1.05122 23.0701 1.02364 22.9952 1Z"
        sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M12.9932 26.6262L21.0184 34.065L32.9971 21.1259"
        sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);
