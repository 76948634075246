import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "../index.type";

export const LogoFigaroEtudiant: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  ...props
}) => (
  <Box {...props}>
    <svg
      width="122"
      height="41"
      viewBox="0 0 122 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_161_1894)">
        <g opacity="0.6">
          <Box
            component="path"
            d="M0.496241 2.37874H7.12201V4.62965H6.2758C6.20357 4.61411 6.12856 4.61737 6.05795 4.63914C5.98735 4.6609 5.92352 4.70042 5.87256 4.75392C5.82161 4.80742 5.78524 4.87312 5.76694 4.9447C5.74865 5.01628 5.74904 5.09136 5.76808 5.16274V10.24C5.77124 10.306 5.78771 10.3707 5.81648 10.4302C5.84526 10.4896 5.88575 10.5427 5.93553 10.5862C5.98532 10.6296 6.04337 10.6626 6.1062 10.6831C6.16904 10.7036 6.23536 10.7111 6.30119 10.7054H8.2136C8.28543 10.7076 8.35698 10.6956 8.42409 10.6699C8.49121 10.6442 8.55255 10.6054 8.60453 10.5558C8.65651 10.5062 8.6981 10.4467 8.72687 10.3809C8.75564 10.315 8.77101 10.2441 8.7721 10.1723V8.35293H11.4207V12.9563H0.420073V10.7138H1.19012C1.26057 10.7308 1.33407 10.7304 1.40434 10.7127C1.47461 10.6951 1.53956 10.6607 1.59362 10.6124C1.64768 10.5642 1.68925 10.5036 1.71477 10.4357C1.74029 10.3679 1.749 10.295 1.74016 10.223V5.14583C1.75919 5.07444 1.75958 4.99935 1.74128 4.92777C1.72299 4.85619 1.68663 4.79051 1.63568 4.73701C1.58472 4.68351 1.52089 4.64398 1.45028 4.62222C1.37968 4.60046 1.30466 4.59719 1.23243 4.61272H0.38623V2.36182L0.496241 2.37874Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M12.6145 2.37866H23.539V5.76347H20.8142V5.19652C20.8195 5.10777 20.8038 5.01903 20.7684 4.93746C20.7331 4.8559 20.679 4.78379 20.6107 4.72696C20.5423 4.67013 20.4616 4.63017 20.3749 4.61031C20.2882 4.59045 20.1981 4.59125 20.1119 4.61264H17.734V6.56736H20.2726V8.62363H17.734V10.7391H20.1542C20.2321 10.7452 20.3104 10.7347 20.3839 10.7082C20.4574 10.6816 20.5243 10.6398 20.5804 10.5853C20.6364 10.5309 20.6802 10.4651 20.7089 10.3924C20.7375 10.3197 20.7504 10.2418 20.7465 10.1637V9.58831H23.539V12.9731H12.6145V10.7307H13.2068C13.272 10.7435 13.3392 10.7414 13.4034 10.7246C13.4677 10.7077 13.5272 10.6765 13.5777 10.6334C13.6282 10.5903 13.6682 10.5363 13.6949 10.4754C13.7215 10.4146 13.734 10.3485 13.7315 10.2822V5.20497C13.7315 4.86649 13.6807 4.64648 13.2407 4.64648H12.6145V2.38712V2.37866Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M28.9373 2.37866H40.0987V5.76347H37.374V5.18805C37.3781 5.10845 37.3649 5.0289 37.3353 4.95491C37.3057 4.88091 37.2603 4.81424 37.2023 4.75951C37.1444 4.70478 37.0752 4.66329 36.9997 4.63793C36.9241 4.61257 36.8439 4.60394 36.7647 4.61264H34.2938V6.56736H36.5785V8.62363H34.2938V10.1553C34.2938 10.5784 34.4715 10.7476 35.0215 10.7476H35.8677V12.99H28.9204V10.7476H29.8428C29.9026 10.7513 29.9626 10.7423 30.0187 10.721C30.0748 10.6998 30.1257 10.6669 30.1682 10.6245C30.2106 10.5821 30.2435 10.5311 30.2647 10.475C30.2859 10.4189 30.295 10.359 30.2913 10.2991V5.2219C30.3104 5.15125 30.3116 5.07694 30.2948 5.0057C30.2779 4.93447 30.2436 4.86856 30.1948 4.81395C30.1461 4.75933 30.0845 4.71773 30.0157 4.69292C29.9468 4.66811 29.8728 4.66087 29.8005 4.67186H28.9543V2.42097L28.9373 2.37866Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M46.3863 5.17122C46.3673 5.09983 46.3669 5.02474 46.3852 4.95316C46.4035 4.88158 46.4399 4.8159 46.4908 4.7624C46.5418 4.7089 46.6056 4.66937 46.6762 4.64761C46.7468 4.62585 46.8218 4.62258 46.8941 4.63811H47.7403V2.38721H41.1483V4.63811H41.9946C42.0668 4.62258 42.1418 4.62585 42.2124 4.64761C42.283 4.66937 42.3468 4.7089 42.3978 4.7624C42.4487 4.8159 42.4851 4.88158 42.5034 4.95316C42.5217 5.02474 42.5213 5.09983 42.5023 5.17122V10.2484C42.5104 10.3164 42.5023 10.3853 42.4785 10.4495C42.4547 10.5137 42.4161 10.5714 42.3656 10.6177C42.3152 10.6639 42.2545 10.6976 42.1885 10.7158C42.1225 10.734 42.0531 10.7362 41.9861 10.7223H41.1399V12.9647H47.7318V10.7223H46.8856C46.8173 10.7349 46.7469 10.7312 46.6803 10.7114C46.6137 10.6917 46.5527 10.6564 46.5024 10.6085C46.452 10.5606 46.4137 10.5015 46.3906 10.436C46.3675 10.3704 46.3602 10.3004 46.3694 10.2315C46.3863 10.13 46.3863 5.39123 46.3863 5.17122Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M57.6916 2.3787H60.0271V6.10198H57.6832C57.4577 5.61766 57.0995 5.20716 56.6502 4.91811C56.2009 4.62905 55.6788 4.47327 55.1446 4.46882C53.723 4.46882 52.606 5.50118 52.606 7.4136C52.606 10.6461 54.383 10.9169 55.1446 10.9169C55.3768 10.9513 55.6137 10.9371 55.8402 10.875C56.0666 10.813 56.2777 10.7045 56.4599 10.5565C56.6422 10.4084 56.7916 10.2241 56.8988 10.0152C57.006 9.80626 57.0685 9.57733 57.0824 9.34294H55.61V7.4136H60.6872C60.6872 7.4136 60.6872 8.20903 60.6872 8.77599C60.6872 9.04677 60.4249 13.1678 54.7638 13.1678C50.6851 13.1678 48.5442 10.8238 48.5442 7.4813C48.5442 5.26425 50.2366 2.2433 54.4253 2.2433C55.6125 2.1539 56.7924 2.49317 57.7509 3.19952V2.40408L57.6916 2.3787Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M73.0077 10.7223C72.5592 10.7223 72.4154 10.3161 72.1615 9.66455C71.9076 9.01298 69.3775 2.38721 69.3775 2.38721H65.3157C65.3157 2.38721 62.5656 9.88456 62.4386 10.2061C62.3869 10.3719 62.2794 10.5146 62.1344 10.6102C61.9895 10.7058 61.816 10.7484 61.6432 10.7308H61.0593V12.9732H66.0181V10.7985H65.3327C65.0365 10.7985 64.9688 10.57 65.0703 10.2484C65.1719 9.92688 65.3834 9.30068 65.3834 9.30068H68.3874L68.709 10.2992C68.7248 10.3672 68.7232 10.4381 68.7043 10.5053C68.6853 10.5726 68.6498 10.6339 68.6008 10.6837C68.5518 10.7335 68.4911 10.7701 68.4242 10.7902C68.3573 10.8103 68.2865 10.8131 68.2182 10.7985H67.6343V12.9309H73.6085V10.6884H73.0077V10.7223ZM65.8488 7.87906L66.8812 4.76503L67.8713 7.87906H65.8488Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M87.0042 10.7223C86.8576 10.7219 86.7146 10.6773 86.5938 10.5943C86.473 10.5112 86.3801 10.3936 86.3272 10.2569C86.1241 9.98611 84.8633 8.01446 84.8633 8.01446C85.3964 7.84879 85.8627 7.51737 86.1945 7.06842C86.5263 6.61947 86.7062 6.07639 86.708 5.51815C86.7013 4.84579 86.4653 4.19585 86.0391 3.67579C85.6129 3.15572 85.022 2.79668 84.364 2.658C83.5019 2.49255 82.627 2.40195 81.7493 2.38721H74.5481V4.63811H75.2589C75.3311 4.62258 75.4061 4.62585 75.4768 4.64761C75.5474 4.66937 75.6112 4.7089 75.6621 4.7624C75.7131 4.8159 75.7495 4.88158 75.7678 4.95316C75.786 5.02474 75.7857 5.09983 75.7666 5.17122V10.1977C75.7804 10.2695 75.7763 10.3437 75.7548 10.4136C75.7333 10.4835 75.695 10.5472 75.6432 10.5989C75.5915 10.6506 75.5279 10.6889 75.4579 10.7105C75.388 10.732 75.3139 10.736 75.242 10.7223H74.5481V12.9647H80.8862V10.7223H80.2346C80.1655 10.7346 80.0945 10.7299 80.0277 10.7086C79.9609 10.6873 79.9003 10.65 79.8511 10.6C79.802 10.55 79.7657 10.4888 79.7455 10.4216C79.7254 10.3545 79.7219 10.2834 79.7353 10.2146V8.52217H81.1485L83.7379 12.9901H87.715V10.7477H87.0042V10.7223ZM81.1908 6.66899H79.7522V4.49426H81.3177C81.4759 4.47248 81.637 4.48487 81.79 4.53063C81.943 4.57638 82.0845 4.65443 82.2048 4.75949C82.3251 4.86456 82.4215 4.99419 82.4874 5.13966C82.5533 5.28514 82.5873 5.44306 82.587 5.60278C82.6039 6.31359 82.0793 6.66899 81.2077 6.66899H81.1908Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M93.6129 2.22632C89.1958 2.22632 87.5964 5.19649 87.5964 7.58278C87.5964 10.7391 89.805 13.1593 93.5622 13.1593C96.947 13.1593 99.5533 11.323 99.5533 7.71818C99.5617 4.29106 97.0401 2.22632 93.6129 2.22632ZM93.6806 10.9253C92.7075 10.9253 91.6836 10.3499 91.6836 7.39661C91.6836 5.57728 92.3775 4.45183 93.5622 4.45183C94.7468 4.45183 95.5 5.20495 95.5 7.60817C95.5084 10.1975 94.6199 10.9253 93.6806 10.9253Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M117.357 2.33637C116.763 2.05568 116.118 1.8974 115.462 1.87096C114.497 1.87096 114.006 2.40406 113.769 3.70722L113.626 4.46034H115.25L115.005 5.78042H113.355L112.272 11.7038C112.13 12.4559 111.817 13.1653 111.358 13.777C110.997 14.1563 110.56 14.4549 110.076 14.6533C109.592 14.8516 109.071 14.9452 108.548 14.9279C108.251 14.9263 107.953 14.9008 107.66 14.8517C107.168 14.7761 106.692 14.6158 106.255 14.3778L107.101 12.9985C107.586 13.2611 108.124 13.4087 108.675 13.4301C109.521 13.4301 110.131 12.7193 110.368 11.6192L111.417 5.82272H109.767L110.004 4.48572H111.696L111.942 3.19103C112.255 1.49863 113.634 0.517042 115.572 0.517042C115.806 0.502783 116.04 0.502783 116.274 0.517042C116.754 0.56823 117.227 0.667443 117.687 0.813205L117.357 2.33637Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M120.429 6.52493C120.338 6.46752 120.232 6.4381 120.124 6.44032C119.801 6.47049 119.5 6.61798 119.278 6.85495C118.887 7.17701 118.572 7.58157 118.356 8.03964C118.102 8.57363 117.936 9.14514 117.865 9.73205L117.637 10.7983H115.775L116.943 4.49405H118.567L118.263 6.33877C118.407 6.12721 118.517 5.96644 118.576 5.86489C118.828 5.52121 119.112 5.20116 119.422 4.90868C119.563 4.75924 119.734 4.64128 119.923 4.56252C120.113 4.48377 120.317 4.44601 120.522 4.45174C120.761 4.45371 120.994 4.52741 121.191 4.66329L120.429 6.52493Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
          <Box
            component="path"
            d="M106.213 7.68433C106.209 8.04008 106.101 8.38687 105.901 8.68104C105.701 8.97521 105.418 9.20359 105.088 9.33742C104.759 9.47125 104.397 9.50454 104.048 9.4331C103.7 9.36165 103.38 9.18867 103.13 8.93593C102.88 8.6832 102.71 8.36202 102.641 8.01285C102.573 7.66369 102.61 7.30216 102.747 6.9738C102.884 6.64543 103.115 6.36494 103.411 6.16762C103.707 5.97031 104.054 5.86501 104.41 5.86499C104.648 5.8661 104.883 5.91404 105.103 6.00607C105.322 6.0981 105.521 6.23241 105.688 6.40136C105.856 6.5703 105.988 6.77055 106.078 6.99069C106.168 7.21082 106.214 7.44652 106.213 7.68433Z"
            sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
          />
        </g>
        <Box
          component="path"
          d="M10.9975 33.9083C11.116 35.7191 12.4276 36.8869 14.7293 36.8869C16.2571 36.935 17.7827 36.7379 19.248 36.303L19.7727 39.8063C17.9196 40.3066 16.0053 40.5431 14.0862 40.5086C8.36585 40.5086 6.36035 37.5639 6.36035 32.8928C6.36035 28.2218 8.37432 25.277 14.0608 25.277C20.3904 25.277 20.8304 29.3895 20.2465 33.849H10.9975V33.9083ZM10.9975 31.192H15.9224C16.1255 29.7957 15.7786 28.6534 13.79 28.6534C13.4359 28.6178 13.0783 28.657 12.7404 28.7686C12.4025 28.8801 12.0918 29.0614 11.8285 29.3008C11.5651 29.5402 11.3551 29.8323 11.212 30.1581C11.0688 30.4839 10.9958 30.8361 10.9975 31.192ZM16.3032 23.8723H12.9184L14.4331 19.7852H18.5711L16.3032 23.8723Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M23.2759 28.7124H21.4058V25.8861L23.2759 25.6238L23.8005 21.5366H27.6507V25.6238H31.0948V28.7124H27.6507V35.3382C27.6507 36.4467 28.2346 37.0306 29.5801 37.0306C30.0009 37.0193 30.42 36.9712 30.8325 36.8867L31.2386 40.2715C30.1852 40.4304 29.1222 40.5181 28.0569 40.5339C24.5875 40.5339 23.2759 39.0784 23.2759 36.0998V28.7463V28.7124Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M32.3473 25.6238H38.2707V34.5512C38.2707 36.1252 39.0323 36.8867 40.3101 36.8867C41.2133 36.819 42.0855 36.5282 42.8487 36.0405V29.2709H41.1563V25.6576H47.2236V36.6583H48.7721V40.2715H42.8487V38.4607H42.7895C42.2265 39.1176 41.5282 39.6453 40.7425 40.0075C39.9568 40.3696 39.1021 40.5579 38.2369 40.5593C35.3175 40.5593 33.862 38.6045 33.862 35.6259V29.2963H32.2881V25.683L32.3473 25.6238Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M57.6658 19.7935H63.5892V36.5906H65.1377V40.2039H59.2143V38.723H59.1551C58.6542 39.269 58.0465 39.7062 57.3696 40.0075C56.6927 40.3088 55.9611 40.4678 55.2202 40.4746C51.1331 40.4746 49.9399 37.0052 49.9399 32.8588C49.9399 28.7124 51.3954 25.243 55.4826 25.243C56.1747 25.2443 56.859 25.3903 57.4914 25.6715C58.1238 25.9528 58.6905 26.3631 59.1551 26.8762H59.2143V23.4067H57.6404V19.7935H57.6658ZM57.0565 36.8867C57.8433 36.8837 58.6085 36.6287 59.2397 36.159V29.6856C58.6085 29.2159 57.8433 28.9608 57.0565 28.9578C55.1272 28.9578 54.6025 30.6502 54.6025 32.9265C54.6025 35.2028 55.1272 36.8783 57.0565 36.8783V36.8867Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M65.8486 25.6238H71.772V36.6244H73.346V40.2377H65.8486V36.6244H67.4226V29.2709H65.8486V25.6576V25.6238ZM71.772 19.7935V24.0245H67.3972V19.7935H71.772Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M74.9877 30.3202V26.03C77.0306 25.5962 79.1107 25.361 81.1988 25.3276C85.0237 25.3276 87.1223 26.2331 87.1223 30.261V36.5906H88.6962V40.2039H82.7728V38.723H82.7135C82.2262 39.2856 81.621 39.7341 80.9411 40.0368C80.2611 40.3395 79.5229 40.489 78.7787 40.4747C76.4178 40.4747 74.0823 39.0446 74.0823 36.0405C74.0823 32.4273 77.3825 31.2849 80.3526 31.2849H82.7728V30.4979C82.7728 29.1863 81.8419 28.924 80.1749 28.924C79.6481 28.9252 79.1221 28.9648 78.601 29.0425V30.2949H74.9877V30.3202ZM82.7728 33.9081H80.9111C79.5657 33.9081 78.4571 34.1112 78.4571 35.5413C78.4674 35.7721 78.5248 35.9984 78.6258 36.2062C78.7269 36.414 78.8694 36.5989 79.0446 36.7495C79.2198 36.9002 79.424 37.0133 79.6446 37.082C79.8652 37.1507 80.0975 37.1736 80.3273 37.1491C81.2025 37.143 82.0563 36.878 82.7812 36.3875V33.9081H82.7728Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M98.6729 36.5906H99.9845V31.2849C99.9845 29.711 99.3414 28.9494 97.9452 28.9494C97.0419 29.0168 96.1696 29.3076 95.4066 29.7956V36.5652H96.7182V40.1785H89.4578V36.5652H91.0317V29.2117H89.4578V25.5984H95.3812V27.4347H95.4658C96.0218 26.7671 96.7179 26.23 97.5046 25.8615C98.2914 25.493 99.1496 25.3021 100.018 25.3022C102.938 25.3022 104.393 27.2824 104.393 30.2356V36.5652H105.942V40.1785H98.7067V36.5652L98.6729 36.5906Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M107.313 28.7124H105.443V25.8861L107.313 25.6238L107.837 21.5366H111.688V25.6238H115.132V28.7124H111.688V35.3382C111.688 36.4467 112.271 37.0306 113.617 37.0306C114.038 37.0193 114.457 36.9712 114.869 36.8867L115.275 40.2715C114.222 40.4304 113.159 40.5181 112.094 40.5339C108.624 40.5339 107.313 39.0784 107.313 36.0998V28.7463V28.7124Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
      </g>
      <defs>
        <clipPath id="clip0_161_1894">
          <rect
            width="120.66"
            height="40"
            fill="white"
            transform="translate(0.496338 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
