import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const HouseInLaptop: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 64,
  height = 48,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 64 48"
      fill="none"
    >
      <g clipPath="url(#clip0_172_901)">
        <Box
          component="path"
          d="M57.9973 47.3898C60.9721 47.3898 63.3862 44.9898 63.3862 42.0325V40.3281H37.0554V40.8C37.0554 42.0488 36.0366 43.0576 34.7845 43.0576C34.7845 43.0576 30.0789 43.0576 29.2155 43.0576C27.9593 43.0576 26.9446 42.0447 26.9446 40.8V40.3281H0.61377V42.0325C0.61377 44.9898 3.02794 47.3898 6.00268 47.3898H57.9932H57.9973Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M17.6685 5.47525H8.27367C6.59603 5.47525 5.23755 6.82576 5.23755 8.49356V40.3281"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M46.3315 5.47525H55.7263C57.404 5.47525 58.7625 6.82576 58.7625 8.49356V40.3281"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M28.9823 0.939659L12.4759 17.3044C11.8049 17.9715 11.8049 19.0576 12.4841 19.7207L14.0513 21.2502C14.7223 21.9051 15.7944 21.901 16.4572 21.238L29.7884 7.98508L43.1195 21.238C43.7823 21.8969 44.8585 21.901 45.5255 21.2502L47.0926 19.7207C47.7719 19.0576 47.7759 17.9715 47.1008 17.3044L30.5944 0.939659C30.1484 0.496269 29.4283 0.496269 28.9823 0.939659Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M34.6944 32.9736H19.1291C18.2125 32.9736 17.4719 32.2373 17.4719 31.3261V20.6237"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M42.1047 20.6237V24.1546"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M42.1007 12.3824V4.42577C42.1007 3.79119 41.581 3.27458 40.9427 3.27458H38.7823C38.1439 3.27458 37.6243 3.79119 37.6243 4.42577V7.91187"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M36.4866 28.4258C35.8032 26.6644 35.259 25.2569 35.259 25.2569L45.0507 29.0237L40.9466 30.9071L39.1544 35.3044C39.1544 35.3044 37.6282 31.3708 36.4866 28.4258Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M40.9426 30.9071L45.0467 34.8854"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_901">
          <rect width="64" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
