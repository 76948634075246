import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Tools: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 49,
  height = 49,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 49 49"
      fill="none"
    >
      <g clipPath="url(#clip0_172_879)">
        <Box
          component="path"
          d="M28.2831 23.624C32.2944 27.6346 35.9943 31.3339 36.7252 32.0647C38.4153 31.3754 40.2881 31.2592 42.0404 31.7117C43.4523 32.0771 44.7894 32.8161 45.8982 33.9205C47.6838 35.7058 48.5101 38.0931 48.3565 40.4348C48.0616 40.2438 44.1707 37.6987 44.1707 37.6987C42.5927 36.7853 40.6286 37.1008 39.3953 38.3339C39.1586 38.5706 38.9468 38.8404 38.7724 39.1435C37.6803 41.0285 38.3364 43.4573 40.2216 44.5451L44.8808 46.7663C41.5671 49.1744 36.9121 48.8796 33.9264 45.8944C31.4473 43.4158 30.8244 39.7788 32.0702 36.723C31.439 36.0919 28.5862 33.2396 25.231 29.8849"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M18.8236 23.4828C15.485 20.1447 12.7194 17.3796 12.2751 16.9395C12.2585 16.9478 12.2419 16.952 12.2294 16.9603C10.5476 17.6329 8.69559 17.7408 6.95568 17.2924C5.54381 16.927 4.20669 16.188 3.09796 15.0836C1.31237 13.2983 0.486014 10.911 0.639658 8.56939C0.934489 8.76037 4.82542 11.3055 4.82542 11.3055C6.40339 12.2189 8.36754 11.9033 9.60085 10.6702C9.83754 10.4336 10.0493 10.1637 10.2237 9.86061C11.3158 7.97568 10.6597 5.54685 8.77449 4.45907C8.77449 4.45907 4.23161 2.29181 4.11534 2.23784C7.43322 -0.174383 12.0882 0.116246 15.0739 3.10142C17.553 5.58007 18.1758 9.21708 16.9342 12.2728C17.4491 12.7877 21.2155 16.5534 25.339 20.6762"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M21.0368 22.0878C20.4347 22.2995 19.8699 22.6441 19.3924 23.1216L3.76635 38.7449C2.05965 40.4514 2.05965 43.2497 3.76635 44.9561C5.47304 46.6625 8.27186 46.6625 9.97855 44.9561L25.6046 29.3327C26.8005 28.137 27.1576 26.414 26.6842 24.8986C28.366 23.2129 38.7515 12.8292 38.7515 12.8292L41.5296 12.688L46.3631 5.22301L43.7387 2.59904L36.26 7.41933L36.1936 10.1429C36.1936 10.1429 26.1527 20.1821 24.1719 22.1625C23.1753 21.7556 22.0583 21.7307 21.0451 22.0836L21.0368 22.0878Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M7.06348 41.6596L22.3075 26.4182"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_879">
          <rect width="49" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
