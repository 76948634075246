import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const LeftCurvedArrowDown: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 17,
  height = 58,

  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 58"
      fill="none"
    >
      <Box
        component="path"
        d="M11.8542 2.30135C12.1831 1.85766 12.09 1.23137 11.6463 0.902499C11.2026 0.573626 10.5763 0.666705 10.2474 1.1104L11.8542 2.30135ZM13.3259 56.4366C13.8757 56.4891 14.3639 56.0859 14.4163 55.5361L15.2711 46.5768C15.3235 46.027 14.9204 45.5388 14.3706 45.4864C13.8208 45.4339 13.3326 45.8371 13.2801 46.3869L12.5203 54.3507L4.5565 53.5909C4.00671 53.5385 3.5185 53.9416 3.46605 54.4914C3.4136 55.0412 3.81677 55.5294 4.36656 55.5819L13.3259 56.4366ZM10.2474 1.1104C3.67909 9.97192 0.624567 19.3231 1.06505 28.6919C1.50528 38.0552 5.4315 47.3371 12.6498 56.0779L14.1919 54.8044C7.19 46.3256 3.47879 37.4449 3.06285 28.598C2.64716 19.7566 5.51771 10.8501 11.8542 2.30135L10.2474 1.1104Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        fillOpacity="0.32" // TODO HANDLE OPACITY ADN COLOR
      />
    </svg>
  </Box>
);
