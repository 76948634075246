import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const BoldEuro: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 49,
  height = 54,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 49 54"
      fill="none"
    >
      <Box
        component="path"
        d="M32.6666 5.4H5.44444V48.6H43.5555V16.2H32.6666V5.4ZM0 2.6784C0 1.1988 1.21683 0 2.7195 0H35.3888L48.9999 13.5V51.2811C49.0024 51.6356 48.9346 51.9872 48.8001 52.3157C48.6656 52.6443 48.4672 52.9435 48.2162 53.1959C47.9652 53.4484 47.6663 53.6493 47.3372 53.7872C47.0078 53.9252 46.6542 53.9976 46.2968 54H2.70316C1.98825 53.9951 1.304 53.7114 0.798209 53.2103C0.292421 52.7091 0.00568944 52.0306 0 51.3216V2.6784Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
      <Box
        component="path"
        d="M31.15 25.5154L30.9426 25.3477C30.1733 24.727 29.2564 24.3069 28.277 24.1276C27.269 23.9429 26.2291 24.0199 25.2605 24.3503C24.2917 24.6811 23.4274 25.254 22.7525 26.0127C22.1523 26.6875 21.72 27.4886 21.4881 28.3523H28.6116V33.7526H21.4881C21.72 34.6163 22.1523 35.4174 22.7525 36.0924C23.4274 36.8511 24.2917 37.424 25.2605 37.7545C26.2291 38.0852 27.269 38.1619 28.277 37.9775C29.1966 37.809 30.0609 37.4286 30.7997 36.8689L31.006 36.7126L34.5218 40.85L34.3018 41.0229C33.0632 41.9954 31.6264 42.7068 30.0813 43.1048C27.9672 43.6494 25.7388 43.5841 23.6615 42.9166C21.5844 42.2492 19.7458 41.008 18.3656 39.3407C17.0371 37.7359 16.1873 35.8013 15.9083 33.7526H13.3369V28.3523H15.9057C16.184 26.3027 17.0336 24.3671 18.3624 22.7611C19.7428 21.0928 21.5822 19.8509 23.6604 19.1834C25.7385 18.5159 27.9683 18.451 30.0829 18.9963C31.692 19.4112 33.1827 20.1661 34.4551 21.2016L34.6682 21.3751L31.15 25.5154Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
