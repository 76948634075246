import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "./index.type";

export const BoldGreekHouse: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 48,
  height = 48,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      fill="none"
    >
      <Box
        component="path"
        d="M0 43.2H48V48H0V43.2ZM4.8 19.8857H9.6V38.7429H4.8V19.8857ZM21.6 19.8857H26.4V38.7429H21.6V19.8857ZM38.4 19.8857H43.2V38.7429H38.4V19.8857ZM0 12L24 0L48 12V21.6H0V12ZM4.8 14.9664V16.8H43.2V14.9664L24 5.3664L4.8 14.9664ZM24 14.4C23.3635 14.4 22.753 14.1471 22.303 13.6971C21.853 13.247 21.6 12.6365 21.6 12C21.6 11.3635 21.853 10.753 22.303 10.3029C22.753 9.85286 23.3635 9.6 24 9.6C24.6365 9.6 25.247 9.85286 25.697 10.3029C26.147 10.753 26.4 11.3635 26.4 12C26.4 12.6365 26.147 13.247 25.697 13.6971C25.247 14.1471 24.6365 14.4 24 14.4Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
