import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "../index.type";

export const LogoFrance3: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  ...props
}) => (
  <Box {...props}>
    <svg
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_161_1922)">
        <Box
          component="path"
          d="M11.0893 18.562C11.0893 21.48 8.72386 23.8455 5.80589 23.8455C2.88793 23.8455 0.522461 21.48 0.522461 18.562C0.522461 15.6441 2.88793 13.2786 5.80589 13.2786C8.72382 13.2786 11.0893 15.6441 11.0893 18.562Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
        <Box
          component="path"
          d="M13.864 24.2052L17.6966 21.0087C18.2743 21.8172 19.0088 22.4817 19.9011 23.0013C20.7934 23.5209 21.7656 23.7812 22.8186 23.7812C23.4733 23.7812 24.1122 23.656 24.7343 23.4056C25.3575 23.1552 25.9097 22.812 26.3908 22.3753C26.872 21.9385 27.2575 21.4119 27.5464 20.7957C27.8353 20.1795 27.9792 19.5051 27.9792 18.7735C27.9792 18.0676 27.8411 17.403 27.565 16.7809C27.289 16.1587 26.9104 15.6154 26.4292 15.154C25.9481 14.6926 25.3703 14.3357 24.6959 14.0852C24.0215 13.8348 23.3058 13.7096 22.5485 13.7096C21.393 13.7096 20.2887 13.9728 19.2367 14.4993V11.36L24.3972 5.23617H15.1734V0.498535H31.927V3.6566L26.9203 9.8189C28.8074 10.5505 30.3445 11.7002 31.5326 13.2659C32.7197 14.8326 33.3143 16.6685 33.3143 18.7735C33.3143 20.2495 33.0442 21.5855 32.5058 22.7785C31.9665 23.9725 31.2378 24.9901 30.3199 25.8311C29.402 26.6721 28.3104 27.3268 27.0464 27.7951C25.7824 28.2634 24.4376 28.4981 23.0119 28.4981C21.2786 28.4981 19.629 28.1323 18.0623 27.4007C16.4956 26.6701 15.0955 25.6053 13.864 24.2052Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
      </g>
      <defs>
        <clipPath id="clip0_161_1922">
          <rect
            width="32.7909"
            height="28"
            fill="white"
            transform="translate(0.522705 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
