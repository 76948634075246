import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Free: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  sColor,
  sColorOption,
  width = 63,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 63 57"
      fill="none"
    >
      <Box
        component="path"
        d="M49.3679 39.7582C50.3267 40.1325 51.463 40.1562 52.4218 39.7701C53.4673 39.3485 53.9447 38.4304 54.2328 37.3981C54.6273 35.9758 55.0298 34.5534 55.4283 33.135C56.0438 30.9522 56.6593 28.7694 57.2748 26.5866C57.5076 25.5425 58.5531 24.8806 59.5948 25.113C60.6403 25.3455 61.3032 26.3896 61.0704 27.4298L58.1586 40.5738C58.1586 40.5738 56.9907 45.2349 53.8816 46.3972C50.7725 47.5635 45.446 49.502 45.446 49.502C45.446 49.502 41.0507 51.0544 40.2734 55.3254"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M56.7379 27.6228C55.7476 27.217 54.6073 27.6898 54.2009 28.6788L52.4097 33.0207"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M45.3906 42.7449L53.6013 36.5787C54.4575 35.9365 54.6311 34.7151 53.988 33.8601C53.3448 33.0051 52.1217 32.8317 51.2655 33.4739L42.2145 39.7977C42.2145 39.7977 39.8827 40.964 38.3242 43.6826C36.7696 46.4013 35.2151 49.1199 35.2151 49.1199"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M12.749 39.7584C11.7903 40.1327 10.654 40.1563 9.6952 39.7702C8.64963 39.3486 8.17222 38.4306 7.8842 37.3983C7.48964 35.9759 7.0872 34.5535 6.6887 33.1351C6.07319 30.9523 5.45769 28.7695 4.84218 26.5867C4.6094 25.5426 3.56383 24.8807 2.5222 25.1132C1.47664 25.3456 0.813785 26.3897 1.04657 27.4299L3.95838 40.574C3.95838 40.574 5.12626 45.235 8.23535 46.3974C11.3444 47.5636 16.6709 49.5021 16.6709 49.5021C16.6709 49.5021 21.0662 51.0545 21.8435 55.3255"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M5.37866 27.6228C6.36899 27.217 7.50925 27.6898 7.91564 28.6788L9.70692 33.0207"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M16.7265 42.7449L8.51581 36.5787C7.65963 35.9365 7.48603 34.7151 8.12915 33.8601C8.77227 33.0051 9.99539 32.8317 10.8516 33.4739L19.9026 39.7977C19.9026 39.7977 22.2345 40.964 23.7929 43.6826L26.902 49.1199"
        sx={(theme) => ({
          stroke: sColor
            ? theme.palette[sColor][sColorOption]
            : theme.palette[mColor][mColorOption],
        })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M31.0604 29.3684C38.905 29.3684 45.2643 23.0179 45.2643 15.1842C45.2643 7.35049 38.905 1 31.0604 1C23.2158 1 16.8564 7.35049 16.8564 15.1842C16.8564 23.0179 23.2158 29.3684 31.0604 29.3684Z"
        sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Box
        component="path"
        d="M34.6783 16.6498L36.6511 17.0674C36.083 19.3211 34.2285 20.7238 31.6205 20.7238C29.1783 20.7238 26.9569 19.4039 26.2033 16.768H24.5659V15.4323H25.9705C25.9547 15.2156 25.939 14.9831 25.939 14.7467C25.939 14.4788 25.9547 14.2306 25.9705 13.9942H24.5659V12.6427H26.2388C27.0082 10.0541 29.2493 8.76965 31.6205 8.76965C34.3114 8.76965 36.2526 10.2551 36.6708 12.5758L34.7296 12.9934C34.41 11.4253 33.2422 10.4718 31.6205 10.4718C30.1173 10.4718 28.8784 11.1889 28.2945 12.6427H33.1751L32.7569 13.9942H27.9591C27.9433 14.2266 27.9236 14.4788 27.9236 14.7467C27.9236 14.9792 27.9394 15.2156 27.9591 15.4323H32.24L31.8376 16.768H28.2589C28.8271 18.2691 30.0976 19.0059 31.6205 19.0059C33.2422 19.0059 34.3469 18.1194 34.6783 16.6537V16.6498Z"
        sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
      />
    </svg>
  </Box>
);
