import { BoxProps } from "@mui/material";

import { ArrowLeft } from "./arrowLeftIcon";
import { ArrowRight } from "./arrowRightIcon";
import { BoldEuro } from "./boldEuroIcon";
import { BoldGreekHouse } from "./boldGreekHouse";
import { BoldShield } from "./boldShield";
import { Broom } from "./broomIcon";
import { Calendar } from "./calendarIcon";
import { Calling } from "./callingIcon";
import { Clock } from "./clockIcon";
import { Free } from "./freeIcons";
import { Guaranteed } from "./guaranteedIcons";
import { HouseInLaptop } from "./houseInLaptop";
import { type OptionalIconColorsProps } from "./index.type";
import { JanitorialExpert } from "./janitorialExpert";
import { Key } from "./keyIcons";
import { LeftCurvedArrowDown } from "./leftCurvedArrowDown";
import { Legal } from "./legalIcons";
import { Linge } from "./lingeIcon";
import { Logo20Minutes } from "./logos/20MinutesLogo";
import { LogoBfm } from "./logos/bfmLogo";
import { LogoC8 } from "./logos/c8";
import { LogoFigaroEtudiant } from "./logos/figaroEtudiant";
import { LogoFigaroImmobilier } from "./logos/figaroImmobilierLogo";
import { LogoForbes } from "./logos/forbesLogo";
import { LogoFrance3 } from "./logos/fr3Logo";
import { LeazlyLogo } from "./logos/keyLeazlyIcon";
import { LogoLeBonbon } from "./logos/leBonbonLogo";
import { LogoLeMonde } from "./logos/leMondeLogo";
import { LogoLeParisien } from "./logos/leParisienLogo";
import { LogoLesEchos } from "./logos/lesEchosLogo";
import { MaxRevenu } from "./maxRevenu";
import { Photo } from "./photoIcon";
import { RightCurvedArrowDown } from "./rightCurvedArrowDown";
import { Shield } from "./shiedIcons";
import { Star } from "./starIcon";
import { Tools } from "./toolsIcon";

export const Icons: React.FC<
  BoxProps & OptionalIconColorsProps & { icon: string }
> = ({
  icon,
  mColor = "primary",
  mColorOption = "main",
  sColor = mColor,
  sColorOption = mColorOption,
  display = "flex",
  ...rest
}) => {
  const props = {
    ...rest,
    mColor,
    mColorOption,
    sColor,
    sColorOption,
    display,
  };
  switch (icon) {
    case "guaranteed":
      return <Guaranteed {...props} />;
    case "legal":
      return <Legal {...props} />;
    case "shield":
      return <Shield {...props} />;
    case "free":
      return <Free {...props} />;
    case "key":
      return <Key {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "linge":
      return <Linge {...props} />;
    case "broom":
      return <Broom {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "photo":
      return <Photo {...props} />;
    case "tools":
      return <Tools {...props} />;
    case "calling":
      return <Calling {...props} />;
    case "houseInLaptop":
      return <HouseInLaptop {...props} />;
    case "leftCurvedArrowDown":
      return <LeftCurvedArrowDown {...props} />;
    case "rightCurvedArrowDown":
      return <RightCurvedArrowDown {...props} />;
    case "boldEuro":
      return <BoldEuro {...props} />;
    case "boldShield":
      return <BoldShield {...props} />;
    case "boldGreekHouse":
      return <BoldGreekHouse {...props} />;
    case "star":
      return <Star {...props} />;
    case "arrowRight":
      return <ArrowRight {...props} />;
    case "arrowLeft":
      return <ArrowLeft {...props} />;
    case "maxRevenu":
      return <MaxRevenu {...props} />;
    case "janitorialExpert":
      return <JanitorialExpert {...props} />;

    // logo
    case "bfm":
      return <LogoBfm {...props} />;
    case "figaroImmobilier":
      return <LogoFigaroImmobilier {...props} />;
    case "forbes":
      return <LogoForbes {...props} />;
    case "leMonde":
      return <LogoLeMonde {...props} />;
    case "lesEchos":
      return <LogoLesEchos {...props} />;
    case "figaroEtudiant":
      return <LogoFigaroEtudiant {...props} />;
    case "leParisien":
      return <LogoLeParisien {...props} />;
    case "fr3":
      return <LogoFrance3 {...props} />;
    case "c8":
      return <LogoC8 {...props} />;
    case "20minutes":
      return <Logo20Minutes {...props} />;
    case "leBonbon":
      return <LogoLeBonbon {...props} />;
    case "leazlyLogo":
      return <LeazlyLogo {...props} />;

    default:
      return <></>;
  }
};
