import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Calendar: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 52,
  height = 49,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 52 49"
      fill="none"
    >
      <g clipPath="url(#clip0_172_806)">
        <Box
          component="path"
          d="M39.2031 4.77545H45.2969C48.6606 4.77545 51.3906 7.56596 51.3906 11.0043V42.1483C51.3906 45.5866 48.6606 48.3771 45.2969 48.3771H6.70312C3.33938 48.3771 0.609375 45.5866 0.609375 42.1483V11.0043C0.609375 7.56596 3.33938 4.77545 6.70312 4.77545H12.7969"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M16.8594 4.77545H35.1406"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M0.609375 15.1568H47.3281"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M16.8594 8.92795C16.8594 10.0741 15.9494 11.0042 14.8281 11.0042C13.7069 11.0042 12.7969 10.0741 12.7969 8.92795V2.69913C12.7969 1.55303 13.7069 0.622864 14.8281 0.622864C15.9494 0.622864 16.8594 1.55303 16.8594 2.69913V8.92795Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M39.2031 8.92795C39.2031 10.0741 38.2931 11.0042 37.1719 11.0042C36.0506 11.0042 35.1406 10.0741 35.1406 8.92795V2.69913C35.1406 1.55303 36.0506 0.622864 37.1719 0.622864C38.2931 0.622864 39.2031 1.55303 39.2031 2.69913V8.92795Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M16.8594 22.4237L10.7656 28.6525L7.71875 25.5381"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M30.9155 22.4237L24.8218 28.6525L21.7749 25.5381"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M44.9761 35.2094L38.8823 41.4382L35.8354 38.3238"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M29.4448 34.1713H22.9448C22.4961 34.1713 22.1323 34.5431 22.1323 35.0018V41.6458C22.1323 42.1045 22.4961 42.4763 22.9448 42.4763H29.4448C29.8936 42.4763 30.2573 42.1045 30.2573 41.6458V35.0018C30.2573 34.5431 29.8936 34.1713 29.4448 34.1713Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M43.6558 21.3856H37.1558C36.707 21.3856 36.3433 21.7575 36.3433 22.2161V28.8602C36.3433 29.3189 36.707 29.6907 37.1558 29.6907H43.6558C44.1045 29.6907 44.4683 29.3189 44.4683 28.8602V22.2161C44.4683 21.7575 44.1045 21.3856 43.6558 21.3856Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M8.51074 34.952L15.4536 42.0528"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M15.4536 34.952L8.51074 42.0528"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_806">
          <rect width="52" height="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
