import { Box, BoxProps } from "@mui/material";

import { IconColorsProps } from "../index.type";

export const LogoC8: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  ...props
}) => (
  <Box {...props}>
    <svg
      width="63"
      height="33"
      viewBox="0 0 63 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_161_1927)">
        <Box
          component="path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.496582 0.5H62.9966V32.5H0.496582V0.5ZM32.554 2.09058H61.3708V30.8866H32.554V2.09058ZM22.6722 25.3111C22.6431 25.5268 22.535 25.6927 22.535 25.6927C22.535 25.6927 22.4187 25.8047 22.2441 25.8586C19.6177 26.9164 16.7129 26.5597 16.7129 26.5597C12.0003 26.0536 9.01656 23.1665 8.05244 19.4498C6.63534 13.8789 9.4446 9.92581 10.1303 9.07545C11.7801 7.09681 14.3649 5.68646 16.9082 5.35461C20.1414 4.90247 22.535 5.65742 23.5241 5.98926C23.5241 5.98926 24.6087 6.32941 25.4191 6.88111C25.8305 7.15488 25.8887 7.26273 25.8887 7.26273C25.8887 7.26273 25.9136 7.3208 25.8014 7.74391L25.0368 11.7344C25.0077 11.9003 24.9079 11.9833 24.9079 11.9833C24.9079 11.9833 24.8539 12.0123 24.4674 11.7634C22.0239 10.0337 19.1648 10.2411 19.1648 10.2411C16.9913 10.378 14.4481 11.5187 13.6917 14.3974C13.0185 16.9527 13.7208 19.0931 15.2418 20.3665C16.2766 21.275 17.8848 21.7271 19.5762 21.6483C21.2825 21.5214 22.6711 20.885 22.7791 20.8355C22.7876 20.8316 22.7881 20.8313 22.7802 20.8353C22.7802 20.8353 22.8592 20.8062 22.9465 20.7814C23.3288 20.5905 23.4119 20.6154 23.4119 20.6154C23.4119 20.6154 23.495 20.6984 23.4368 20.9473L22.6722 25.3111ZM54.1378 11.1216C54.0015 12.2437 53.5234 14.4268 51.0452 15.4499C54.2448 16.458 54.8324 19.0079 54.9935 20.284C55.2542 22.3483 53.1893 26.9719 47.0922 26.7824H46.8434C40.7463 26.9719 38.6811 22.3483 38.9418 20.284C39.1029 19.008 39.6905 16.458 42.8901 15.4499C40.4118 14.4268 39.9337 12.2437 39.7974 11.1216C39.5645 9.20471 41.4092 4.91145 46.8563 5.0874H47.0786C52.5257 4.91145 54.3707 9.20471 54.1378 11.1216ZM49.2913 11.0543C49.2913 12.2628 48.2722 13.2426 47.015 13.2426C45.7579 13.2426 44.7388 12.2628 44.7388 11.0543C44.7388 9.84571 45.7579 8.86597 47.015 8.86597C48.2722 8.86597 49.2913 9.84571 49.2913 11.0543ZM47.015 22.3809C48.3785 22.3809 49.4839 21.3228 49.4839 20.0176C49.4839 18.7123 48.3785 17.6542 47.015 17.6542C45.6515 17.6542 44.5461 18.7123 44.5461 20.0176C44.5461 21.3228 45.6515 22.3809 47.015 22.3809Z"
          sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        />
      </g>
      <defs>
        <clipPath id="clip0_161_1927">
          <rect
            width="62.5"
            height="32"
            fill="white"
            transform="translate(0.496582 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
