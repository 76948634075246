import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Legal: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 64,
  height = 56,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 64 56"
      fill="none"
    >
      <g clipPath="url(#clip0_172_970)">
        <Box
          component="path"
          d="M31.943 13.5459C34.3312 13.5459 36.2673 11.6125 36.2673 9.22761C36.2673 6.84267 34.3312 4.9093 31.943 4.9093C29.5547 4.9093 27.6187 6.84267 27.6187 9.22761C27.6187 11.6125 29.5547 13.5459 31.943 13.5459Z"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M63.2944 32.9783C63.2944 37.7497 58.4532 41.6149 52.4836 41.6149C46.514 41.6149 41.6729 37.7497 41.6729 32.9783H63.2944Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M31.9429 13.5459V45.9332"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M31.9429 0.591064V4.90937"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M52.4838 9.22766H36.2676"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M27.619 9.22766H11.4028"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M41.6729 32.9783L52.4836 9.22766L63.2944 32.9783"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M22.2134 32.9783C22.2134 37.7497 17.3722 41.6149 11.4026 41.6149C5.43298 41.6149 0.591797 37.7497 0.591797 32.9783H22.2134Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M0.591797 32.9783L11.4026 9.22766L22.2134 32.9783"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M24.3757 50.2515V48.0924C24.3757 46.8985 25.3423 45.9332 26.5378 45.9332H37.3486C38.5441 45.9332 39.5108 46.8985 39.5108 48.0924V50.2515H45.9972C47.1927 50.2515 48.1594 51.2169 48.1594 52.4107V54.5698H15.7271V52.4107C15.7271 51.2169 16.6937 50.2515 17.8892 50.2515H33.0243"
          sx={(theme) => ({ stroke: theme.palette[sColor][sColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_970">
          <rect width="63.8862" height="55.1609" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
