import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Clock: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 66,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 66 57"
      fill="none"
    >
      <g clipPath="url(#clip0_172_837)">
        <Box
          component="path"
          d="M37.6215 0.619568C52.9543 0.619568 65.3821 13.1017 65.3821 28.5C65.3821 43.8983 52.9543 56.3804 37.6215 56.3804C22.2886 56.3804 9.86084 43.8983 9.86084 28.5"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M37.6217 31.6433C39.349 31.6433 40.7492 30.236 40.7492 28.5C40.7492 26.764 39.349 25.3568 37.6217 25.3568C35.8944 25.3568 34.4941 26.764 34.4941 28.5C34.4941 30.236 35.8944 31.6433 37.6217 31.6433Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M37.6216 6.50958V25.3568"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M55.1177 29.2848H59.8111"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M37.9141 47.3472V52.06"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M45.937 44.1833L48.2198 48.3013"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M21.8559 37.8678L17.7559 40.1561"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M53.9722 19.917L58.0722 17.6287"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M52.8804 37.4259L57.0134 39.6563"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M40.3701 30.3546L48.0798 34.7824"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M29.2073 45.1457L26.9863 49.2967"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M46.6211 12.6391L48.8462 8.48804"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M0.206055 20.8876L7.80448 14.1881C10.1986 12.0691 11.237 10.3137 11.237 8.39305C11.237 5.93544 9.60519 4.37827 7.07101 4.37827C4.00527 4.37827 2.28697 6.44349 2.62486 9.57849L0.766459 9.43805C0.515101 5.30762 2.9916 2.62283 7.07513 2.62283C10.7919 2.62283 13.2107 4.88631 13.2107 8.38892C13.2107 10.7639 11.9457 12.9407 9.01594 15.5139L2.88034 20.8835H13.2107V22.6348H0.206055V20.8835V20.8876Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          stroke={mColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M26.1204 17.4098H15.481V15.6585L25.6671 2.85001H28.0035V15.6544H30.9869V17.4057H28.0035V22.6348H26.1163V17.4057L26.1204 17.4098ZM26.1204 15.6585V4.91522L17.5907 15.6585H26.1204Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          stroke={mColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_837">
          <rect width="66" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
