import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const Calling: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 48,
  height = 57,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 57"
      fill="none"
    >
      <g clipPath="url(#clip0_172_887)">
        <Box
          component="path"
          d="M29.501 42.4402V39.8876"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M18.4946 39.8876V42.4402"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M0.611328 56.3804V50.8043C0.611328 48.355 2.27859 46.232 4.63477 45.6826L18.4987 42.4402"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M29.501 42.4402L43.3649 45.6826C45.717 46.232 47.3884 48.355 47.3884 50.8043V56.3804"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M41.8854 45.3398V18.7439C41.8854 8.73174 33.8793 0.619568 23.9981 0.619568C14.1168 0.619568 6.11475 8.73174 6.11475 18.7439V45.3439"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M28.1272 8.9837C28.1272 14.3739 32.44 18.7439 37.7557 18.7439C40.0344 18.7439 41.8851 20.615 41.8851 22.9239C41.8851 25.2328 40.0385 27.1039 37.7557 27.1039C37.7557 34.803 31.5962 41.0441 23.9977 41.0441C16.3993 41.0441 10.2398 34.803 10.2398 27.1039C7.96105 27.1039 6.11035 25.2328 6.11035 22.9239C6.11035 20.615 7.95698 18.7439 10.2398 18.7439C20.117 18.7439 28.1231 14.3739 28.1231 8.9837"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M29.501 42.4402C29.501 42.4402 28.1232 48.0163 23.9978 48.0163C19.8725 48.0163 18.4946 42.4402 18.4946 42.4402"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M23.9121 35.7448C24.8554 35.7448 25.6202 34.9699 25.6202 34.0141C25.6202 33.0583 24.8554 32.2835 23.9121 32.2835C22.9688 32.2835 22.2041 33.0583 22.2041 34.0141C22.2041 34.9699 22.9688 35.7448 23.9121 35.7448Z"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Box
          component="path"
          d="M37.7804 27.108C36.3659 31.0815 32.6074 33.9233 28.1967 33.9233H25.6489"
          sx={(theme) => ({ stroke: theme.palette[mColor][mColorOption] })}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_172_887">
          <rect width="48" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>{" "}
  </Box>
);
