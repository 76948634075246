import { Box, type BoxProps } from "@mui/material";

import { type IconColorsProps } from "./index.type";

export const RightCurvedArrowDown: React.FC<BoxProps & IconColorsProps> = ({
  mColor,
  mColorOption,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sColorOption,
  width = 17,
  height = 58,
  ...props
}) => (
  <Box {...props} height={height} width={width}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 17 58"
      fill="none"
    >
      <Box
        component="path"
        d="M5.14581 2.30135C4.81694 1.85766 4.91002 1.23137 5.35371 0.902499C5.7974 0.573626 6.42369 0.666705 6.75256 1.1104L5.14581 2.30135ZM3.67413 56.4366C3.12434 56.4891 2.63613 56.0859 2.58367 55.5361L1.72891 46.5768C1.67646 46.027 2.07963 45.5388 2.62942 45.4864C3.1792 45.4339 3.66742 45.8371 3.71987 46.3869L4.47966 54.3507L12.4435 53.5909C12.9933 53.5385 13.4815 53.9416 13.534 54.4914C13.5864 55.0412 13.1832 55.5294 12.6334 55.5819L3.67413 56.4366ZM6.75256 1.1104C13.3209 9.97192 16.3754 19.3231 15.9349 28.6919C15.4947 38.0552 11.5685 47.3371 4.35022 56.0779L2.80809 54.8044C9.81 46.3256 13.5212 37.4449 13.9372 28.598C14.3528 19.7566 11.4823 10.8501 5.14581 2.30135L6.75256 1.1104Z"
        sx={(theme) => ({ fill: theme.palette[mColor][mColorOption] })}
        fillOpacity="0.32" // TODO HANDLE OPACITY ADN COLOR
      />
    </svg>
  </Box>
);
